@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --color-primary: #672d5f;
    --color-secondary: #daa028;
    --color-white: #ffffff;
    --color-light: #f5f1eb;
    --color-gray-100: #f4f5f8;
    --color-gray-200: #e3e7ed;
    --color-gray-300: #cdd4e0;
    --color-gray-400: #b4bdce;
    --color-gray-500: #97a3b9;
    --color-gray-600: #677793;
    --color-gray-700: #596882;
    --color-gray-800: #3b4863;
    --color-gray-900: #1c273c;
    --shadow-darker: 0px 10px 50px 0px rgba(26, 46, 85, 0.1);
    --shadow-darker2: 0px 20px 50px 0px rgba(26, 46, 85, 0.1);
    --shadow-dark: 0px 10px 30px 0px rgba(20, 36, 66, 0.15);
    --shadow-darkest: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
    --shadow-darker3: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
    --shadow-darker4: 0px 20px 70px 0px rgba(15, 107, 92, 0.2);
    --transition: 0.3s;
    --transition-2: 0.5s;
    --transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);
    --font-primary: "Raleway", sans-serif;
}

/*=====================================================================*/
body {
    font-family: var(--font-primary) !important;
    font-size: 14px !important;
    line-height: 26px !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

a {
    text-decoration: none !important;
}

img {
    max-width: 100%;
}

textarea:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: var(--color-gray-200);
    box-shadow: none;
    outline: 0 none;
}

/*=====================================================================*/
.tx-12 {
    font-size: 12px;
}

.tx-primary {
    color: var(--color-primary);
}

.tx-red {
    color: #db1c1c;
}

.tx-gray {
    color: var(--color-gray-600);
}

.img-fluid {
    width: 100%;
}

.btn-full {
    width: 100%;
}

.btn {
    padding: 7px 15px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
}

.btn-sm {
    padding: 6px 10px !important;
    font-size: 13px;
}

.btn-lg {
    padding: 12px 15px !important;
    font-size: 16px;
}

.btn-primary {
    background: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

.section-gap-md {
    padding: 60px 0px;
}

.section-title {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

/*=====================================================================*/
.header {
    position: relative;
    width: 100%;
    padding: 15px 0px;
    background-color: var(--color-white);
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, .05);
}

.header .container,
.header .container-fluid,
.header-center,
.header-left,
.header-right {
    align-items: center;
    display: flex;
}

.header-left,
.header-right {
    flex: 1 1;
}

/*=====================================================================*/
.herobanner {
    height: 600px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--color-gray-100);
    overflow: hidden;
}

.herobanner .herobanner-content {
    width: 100%;
    position: relative;
}

.herobanner .title {
    line-height: 52px;
}

.herobanner .herobanner-img {
    transform: matrix(1, 0, 0, 1, -13.36, 10.38);
    margin-left: 70px;
    position: relative;
    z-index: 1;
}

.herobanner .hero-shape {
    position: absolute;
    z-index: -1;
    top: 160px;
    left: -120px;
    right: 0;
    text-align: center;
}

.herobanner .hero-img-shape .shape1 {
    position: absolute;
    top: 30px;
    left: -100px;
    z-index: -1;
    animation: moveleftbounce 9s linear infinite;
}

.herobanner .hero-img-shape .shape2 {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: -1;
    animation: movebounce 5s linear infinite;
}

.heroSearch {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.heroSearch input {
    width: 100%;
    height: 60px;
    border: 0px;
    background: var(--color-white);
    padding: 15px 15px 15px 60px;
    border-radius: 5px 0px 0px 5px;
}

.heroSearch .heroSearchButton {
    background: var(--color-primary);
    border: 0px;
    height: 60px;
    width: 150px;
    color: var(--color-white);
    font-weight: 700 !important;
    border-radius: 0px 5px 5px 0px;
}

.heroSearch .heroSearchIcon {
    position: absolute;
    top: 0;
    font-size: 1.5rem;
    left: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
}

ul.heroSearch-list {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 19%);
    overflow: auto;
    position: absolute;
    right: 0;
    top: 57px;
    transition: height .15s ease-in-out;
    width: 100%;
    z-index: 3;
    padding: 10px 0px;
    height: 175px;
    overflow-y: auto;
    overflow-x: hidden;
}

ul.heroSearch-list li {
    color: #2E054E;
    display: flex;
    font-size: 14px;
    padding: 4px 12px;
    text-decoration: none;
    cursor: pointer;
    flex-direction: column;
}

/*=====================================================================*/
.propbox {
    width: 100%;
    position: relative;
    padding: 35px 25px;
    border: 1px solid var(--color-gray-200);
    text-align: center;
    border-radius: 7px;
    min-height: 275px;
}

.propbox h2 {
    font-size: 16px;
    line-height: 24px;
}

.propboxMedia {
    width: 75px;
    margin: 0 auto;
    height: 75px;
    overflow: hidden;
}

.propboxMedia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.propboxContent {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

/*=====================================================================*/
/*=====================================================================*/
.footer {
    width: 100%;
    position: relative;
    padding: 60px 0px;
    background: var(--color-gray-100);
}

.footer-logo {
    width: 100%;
    display: block;
}

.footer-title {
    width: 100%;
    position: relative;
    font-size: 18px;
}

.footer-list {
    width: 100%;
    position: relative;
}

.footer-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-list ul li {
    display: inline-block;
    width: 100%;
    position: relative;
    line-height: 32px;
    font-size: 13px;
}

.footer-list ul li a {
    display: inline-block;
    width: 100%;
    position: relative;
    color: var(--color-gray-900);
}

ul.footer-social {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px;
}

ul.footer-social li {
    display: inline-block;
    position: relative;
}

ul.footer-social li a {
    color: var(--color-gray-600) !important;
}

ul.footer-social li a i {
    font-size: 1.5rem;
}

.copyright {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 10px 0px;
}

.newsletter {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.newsletter input {
    width: 100%;
    height: 60px;
    border-radius: 7px 0px 0px 7px;
    border: 0px;
    background: var(--color-white);
    padding: 15px;
}

.newsletterbtn {
    width: 150px;
    height: 60px;
    border-radius: 0px 7px 7px 0px;
}

/*=====================================================================*/
.loginModal .modal-content {
    border-radius: 0;
    border: none;
}

.loginContant {
    background: var(--color-light);
    width: 100%;
    position: relative;
    padding: 50px;
}

/*=====================================================================*/
.formgroup {
    width: 100%;
    position: relative;
}

.formgroup label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 0.3rem;
    font-size: 14px;
}

.formgroup input {
    width: 100% !important;
    height: 50px;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px !important;
    font-size: 14px;
    padding: 15px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: 0;
    font-size: 2rem;
}

.form-group {
    width: 100%;
    position: relative;
}

.form-group label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 0.3rem;
    font-size: 14px;
}

.form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px !important;
    font-size: 14px;
    padding: 15px;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: 50px;
    padding: 0.46875rem 1.625rem 0.46875rem 0.625rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.625rem center/8px 10px !important;
    background-color: rgba(0, 0, 0, 0);
    background-color: #fff;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    appearance: none;
}

/*=====================================================================*/
.edublog {
    width: 100%;
    position: relative;
}

.edublog .edublogMedia {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 250px;
    border-radius: 10px;
}

.edublog .edublogMedia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s;
}

.edublog .edublogMedia:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.edublog .edublogContent {
    padding: 20px 0px;
}

.edublogContent .shortdesc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/*=====================================================================*/
.propertyModal .modal-dialog {
    max-width: 500px;
}

.propertyModalContent {
    padding: 35px;
    width: 100%;
    position: relative;
}

/*=====================================================================*/
.otp-input>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

/*=====================================================================*/
/*=====================================================================*/
.ccpanel {
    width: 100%;
    position: relative;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
}

.ccpanel .ccpanel-header {
    padding: 15px;
    border-bottom: 1px solid var(--color-gray-200);
}

.ccpanel .ccpanel-body {
    padding: 15px;
}

.ccpanel .ccpanel-footer {
    padding: 15px;
    border-top: 1px solid var(--color-gray-200);
}

.aobox {
    width: 100%;
    position: relative;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--color-dark);
    padding: 15px;
}

.aobox:hover {
    background: var(--color-gray-100);
}

.aobox img {
    width: 32px;
    height: 32px;
}

.profilebox {
    width: 100%;
    position: relative;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
    padding: 10px 15px;
    gap: 10px;
    display: flex;
    align-items: center;
}

.profilebox .profileboxMedia {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    overflow: hidden;
}

.profileboxMedia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aclist {
    width: 100%;
    position: relative;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
    padding: 10px 0px;
}

.aclist ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.aclist ul li {
    padding: 5px 15px;
    display: inline-block;
    width: 100%;
    position: relative;
}

.aclist ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-dark);
}

.aclist ul li.active a {
    color: var(--color-primary);
}
.helpbox {
    width: 100%;
    position: relative;
    border-bottom: 3px solid var(--color-gray-100);
    }





/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/
/*=====================================================================*/